import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Main from "./components/pages/Main";
import Footer from "./components/inc/Footer";
import Contacts from './components/pages/Contacts';
import Fingerboard from './components/pages/Fingerboard';
import Woodenfloor from './components/pages/Woodenfloor';
import Distributorpage from './components/pages/Distributorpage';
import Aboutus from './components/pages/Aboutus';


function App() {
  return (
    <Router>
    
    <div>

     
      
      <Routes>

      <Route axact path="/" element={<Main/>}/>
      <Route axact path="/Contact-us" element={<Contacts/>}/>
      <Route axact path="/finger-joint-board" element={<Fingerboard/>}/>
      <Route axact path="/Woodenfloor" element={<Woodenfloor/>}/>
      <Route axact path="/distributor-page" element={<Distributorpage/>}/>
      <Route axact path="/Aboutus" element={<Aboutus/>}/>
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
