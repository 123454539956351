import React from 'react'
import Navbar from '../inc/Navbar'
import About from "../images/about.jpg";

function Aboutus() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-3">
                     
                            <div className="card-body">
                                <h5 style={{color:"darkBlue"}} className="card-title texts">RMI Woody, crafts high-quality woden finger joint board, meticulously joining individual pieces of wood to create durable and beautiful panels, Our finger joint boards are ideal for various applications, including furniture, cabinetry flooring, and decorative accents. With a commiment to craftsmanship and sustainability, RMI Woody delivers reliable and visually oppealing wood products that stand the test of time</h5>
                           
                            </div>

                        </div>
                        <div className="col-md-6 mt-3">
                            <img src={About} className="card-img-top" alt="Aboutus"/>
                         

                        </div>
                    </div>

                </div>
            </div>
            <br />
            <br />
        </div>
    )
}

export default Aboutus