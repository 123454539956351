import React from 'react'
import Fingerjointdoor from "../images/boardjoin.jpg"
import Fingerjointdoorboard from "../images/jointboarddoor.jpg";
import Sidefingerdoorjoitn from "../images/side-fingerdoorjoint.jpg";
import Fingerjointdoornew from "../images/card4.jpg";
import fingerdoorjointboardberhampore from "../images/doorboardfinger.jpg"
import doorsize from "../images/finger joint board.jpeg"
import Fingerdoorsize from "../images/door size.jpeg";

function Fingerdoor() {
    return (
        <div>

            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <img src={Fingerjointdoor} className="card-img-top" alt="Fingerjointdoorberhampore" />
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"green"}} className="card-title">Finger Joint Door Board</h5>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <img src={Fingerjointdoorboard} className="card-img-top" alt="Fingerjointdoorberhampore" />
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"green"}} className="card-title">Finger Joint Door Board</h5>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <img src={Sidefingerdoorjoitn} className="card-img-top" alt="Fingerjointdoorberhampore" />
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"green"}} className="card-title">Finger Joint Door Board</h5>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <img src={Fingerjointdoornew} className="card-img-top" alt="Fingerjointdoorberhampore" />
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"green"}} className="card-title">Finger Joint Door Board</h5>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <img src={fingerdoorjointboardberhampore} className="card-img-top" alt="Fingerjointdoorberhampore" />
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"green"}} className="card-title">Finger Joint Door Board</h5>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <img src={doorsize} className="card-img-top" alt="Fingerjointdoorberhampore" />
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"green"}} className="card-title">Finger Joint Door Size Board</h5>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <img src={Fingerdoorsize} className="card-img-top" alt="Fingerjointdoorberhampore" />
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"green"}} className="card-title">Finger Joint Door Size Board</h5>
                                    </center>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default Fingerdoor