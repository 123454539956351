import React from 'react'
import Navbar from '../inc/Navbar'
import { Link } from "react-router-dom";
import fingerjointboard from "../images/fingerboard.jpg";
import Fingerdoor from './Fingerdoor';
import ScrollToTop from "react-scroll-to-top"
import ReactWhatsapp from "react-whatsapp";


function Fingerboard() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br/>
      <div style={{color: "Green"}} class="fixed-bottom "> <ReactWhatsapp number="+91  9851512851" className="whatsappbutton" message="I am Interested"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"  fill="currentColor" class="bi bi-whatsapp"  viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
</svg> Whatsapp  <div className="spinner-grow text-light spinner-grow-sm"  role="status">
              <span className="visually-hidden"> Loading...</span>
            </div></ReactWhatsapp></div>
      <ScrollToTop smooth top="100" color="Gray"/>
      <div className="bestfingerjointboardmurshidbad">
        <br />
     

        <div className="album py-1">

          <div className="container">
            <h2 style={{ color: "white" }} className='mt-3'><b>FINGER JOINT BOARD</b></h2>
            <h4 style={{ color: "white" }}>Manufacture Solid Wooden Finger Joint Board</h4>
          </div>
        </div>
        <div className="album py-1">

          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link style={{ color: "white" }} to="/"> Home</Link></li>
                {/* <li style={{ color: "white" }} className="breadcrumb-item"><Link style={{ color: "white" }} to="#" >About Us</Link></li> */}
                <li className="breadcrumb-item"><Link style={{ color: "white" }} to="/Woodenfloor" >Wooden Floor</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Finger Joint Board <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                  <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                </svg></li>
              </ol>
            </nav>
          </div>

        </div>
      </div>
      <br />
      <div className="album py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-3">


              <img src={fingerjointboard} className="card-img-top" alt="fingerjointboard" />


            </div>
            <div className="col-md-6 mt-3">


              <div className="card-body">
                <h5 className="card-title">FINGER JOINT BOARD</h5>
                <p style={{ color: "grey" }} className="card-text">For use all type of furniture, door and window</p>
                <h4 style={{ color: "red" }} className="card-text">Benefits of Use</h4>

                <p className='mb-1 texts'>1.Immune to Decay: It is resistant to the attack of fungus, white ants and inscets.</p>
                          <p className='mb-1 texts'>2. It gives a flawless finish that hides minor imperfections</p>
                          <p className='mb-1 texts'> 3. Furniture made out <b>RMI Manufacture solid wooden finger joint board </b>has an attractive looking. It is smooth to touch and has a rich brown color
                            after polishing (Only Gold Section Plank) in comparison to furniture made out of plywood or particle boards.
                          </p>
                          <p className='mb-1 texts'> 4. Durability: <b>RMI Manufacture solid wooden finger joint board </b> furniture can last for up to many years, some have not perished for as long as 100 years. Besides, it doesn't get damaged while shifting places because of water.</p>
                          <p className='mb-1 texts'> 5. Resistant to rot and decay: Furniture made of: RMI <b>Manufacture solid wooden finger joint board</b> is durable and does not rot because of its high density. Good wood furniture should stay in proper shape for a long time</p>




              </div>
            </div>
          </div>
        </div>
      </div>
      <Fingerdoor/>
      <br />

    </div>
  )
}

export default Fingerboard