import React from 'react'
import wooderfoorberhampore from "../images/WOODEN FOOR.jpg";
import Fingejointboard from "../images/bannar-home.jpg";
import doorboard from "../images/doorboard.jpg";
import Doorboardfinger from "../images/finger-doorboard.jpg";
import Mainbanner from "../images/main banner.jpg";


function Slider() {
  return (
    <div>
<div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>

  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={Mainbanner} className="d-block w-100" alt="Mainbanner"/>
    </div>
    <div className="carousel-item">
      <img src={doorboard} className="d-block w-100" alt="Fingejointboard"/>
    </div>
    <div className="carousel-item">
      <img src={wooderfoorberhampore} className="d-block w-100" alt="Fingejointboard"/>
    </div>
    <div className="carousel-item">
      <img src={Fingejointboard} className="d-block w-100" alt="Fingejointboard"/>
    </div>
   
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

    </div>
  )
}

export default Slider