import React from 'react'

function Mapgoogle() {
    return (
        <div>
            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        <iframe className="iframe-fluid" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=R%20M%20POWER%20SYSTEM,%2048JP+796,%20Berhampore%20-%20Islampur%20Rd,%20Nimtala,%20Berhampore,%20West%20Bengal%20742102%20+(R%20M%20POWER%20SYSTEM,%2048JP+796,%20Berhampore%20-%20Islampur%20Rd,%20Nimtala,%20Berhampore,%20West%20Bengal%20742102)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> <a href='https://www.versicherungen.at/cyber-versicherung/'></a>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Mapgoogle