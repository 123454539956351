import React from 'react'
import { Link } from "react-router-dom";
import Logorm from "../images/RMIDOODY-LOGO.jpg";
import "./Navbar.css";

function Navbar() {
  return (
    <div>
      <div className='fixed-top'>

        <div className='woodenfloorberhamporesss'>
          <div className="album py-2">
            <div className="container">
              <div className="row ">
                <div className="col-md-5" >
                  <div className="d-flex justify-content-between align-items-center">
                    {/* <h4 style={{ color: "Darkblue" }} > <b> {ctime} </b></h4> */}

                    <a style={{ color: "white" }} href="tel: +91 9851512851" >    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                    </svg> +91 9851512851 <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                      <span class="visually-hidden">Loading...</span></a>


                    <Link to="/distributor-page">
                      <div style={{ pading: "1px" }} className="btn-grad16"> <small>Apply For Distributor</small></div>
                    </Link>


                  </div>

                </div>

                <div className="d-flex justify-content-between align-items-center">





                </div>
              </div>
            </div>
          </div>
        </div>


        <nav className="navbar navbar-expand-lg navbar-white bg-white shadow">
          <div className="container-fluid">
            <Link to="/">
              <img src={Logorm} height="50" width="150" alt='rminternationallogo' />


            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link to="/" className="nav-link active me-2" aria-current="page" href="#">Home</Link>
                </li>
                <li className="nav-item">
                  <Link to="/Aboutus" className="nav-link" href="#">About Us</Link>
                </li>

                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Product
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link to="/finger-joint-board" className="dropdown-item" href="#">Finger Joint Board</Link></li>
                    <li><Link to="/Woodenfloor" className="dropdown-item" href="#">Wooden Floor</Link></li>

                  </ul>
                </li>
                <li className="nav-item">
                  <Link to="/Contact-us" className="nav-link" href="#">Contact Us</Link>
                </li>
              </ul>
              <a href='tel: 9851512851'>
                <button className="woodenfloorberhampore woodenfllormursidabad" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-plus" viewBox="0 0 16 16">
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                  <path fill-rule="evenodd" d="M12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5" />
                </svg> +91 9851512851 </button>
              </a>

            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Navbar