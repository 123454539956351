import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'










function Woodenpage() {
  const [setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPerPage, setShowPerPage] = useState(12);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const [searchTerm, setsearchTerm] = useState("");
  const [woodenpage, setWoodenpage] = useState([])
  const navigate = useNavigate()

  const woodenpageCollectionRef = collection(db, "Wooden-Gallery");
  useEffect(() => {

    const getWoodenpage = async () => {
      const data = await getDocs(woodenpageCollectionRef);
      setWoodenpage(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    getWoodenpage()
  }, [])
  return (

    <>
   

      <br />
   


      <div className="album py-2">

        <div className="container">

          <div className="row">
            {woodenpage.filter((val) => {
              if (setsearchTerm === "") {
                return val;
              } else if (
                val.img.toLowerCase().includes(searchTerm.toLowerCase())




              ) {
                return val;
              }
            }).slice(pagination.start, pagination.end).map((rminternational) => {
              return (
                <div className="col-md-4 mt-8" >
                  <div className="card mb-4 ">
                    <img className="card-img-top" src={rminternational.img} alt="rminternational" />
                    <div className="card-body">
                                    <center>
                                        <h5 style={{color:"green"}} className="card-title">{rminternational.title} </h5>
                                    </center>
                                </div>
                  </div>

                </div>
              )
            })}
          </div>

       

        </div>
      </div>

    </>

  );
}

export default Woodenpage;


