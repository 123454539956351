

import React from 'react'
import Navbar from "../inc/Navbar";
import AddDistributor from '../AddDistributor';
import ScrollToTop from "react-scroll-to-top"

function Distributorpage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <ScrollToTop smooth top="100" color="Gray"/>
        <AddDistributor/>
    </div>
  )
}

export default Distributorpage