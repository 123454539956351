
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyCcYStwzNPY3zBoqqYqx2ZXEg4tDbtZ2_s",
    authDomain: "rm-international-44112.firebaseapp.com",
    projectId: "rm-international-44112",
    storageBucket: "rm-international-44112.appspot.com",
    messagingSenderId: "400309552875",
    appId: "1:400309552875:web:b2b2e2ada42a52e6f060ae",
    measurementId: "G-3YFREVN32S"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;